import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editReview: {},
    isLoading: false,
    error: null
}

const editReviewSlice = createSlice({
    name: 'editReview',
    initialState,
    reducers:{
        editReviewPending: (state) => {
            state.isLoading = true;
        },
        editReviewSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editReview = payload?.response;
            state.error = null;
        },
        editReviewFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {editReviewPending, editReviewSuccess, editReviewFail} = editReviewSlice.actions

export default editReviewSlice.reducer