import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscription: {},
    loading: false,
    error: ''
}

const getSubscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers:{
        getSubscriptionPending: (state) => {
            state.loading = true;
        },
        getSubscriptionSuccess: (state, {payload}) => {
            state.loading = false;
            state.subscription = payload?.subscription;
            state.error = '';
        },
        getSubscriptionFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {getSubscriptionPending, getSubscriptionSuccess, getSubscriptionFail} = getSubscriptionSlice.actions

export default getSubscriptionSlice.reducer