import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reviews: {},
    loading: false,
    error: null
}

const reviewslice = createSlice({
    name: 'reviews',
    initialState,
    reducers:{
        reviewsPending: (state) => {
            state.loading = true;
        },
        reviewsSuccess: (state, {payload}) => {
            state.loading = false;
            state.reviews= payload?.reviews;
            state.error = null;
        },
        reviewsFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {reviewsPending, reviewsSuccess, reviewsFail} = reviewslice.actions

export default reviewslice.reducer